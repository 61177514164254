import styled from 'styled-components';
import { theme } from '@redislabsdev/redis-ui-styles';
import { InfoIcon } from '@redislabsdev/redis-ui-icons';
import { cardWithShadow } from '../../../../styles/commonAccountDetails.style';

export const InfoSection = styled.div`
  padding-bottom: 2rem;
  ${cardWithShadow}
`;

export const SpanWithGrace = styled.span`
  display: flex;
`;

export const GracePeriodWrapper = styled.span`
  margin-left: 1rem;

  & > span {
    margin-bottom: -2px;
    padding-left: 4px;
    color: ${theme.semantic.color.text.notice500};
  }
`;

export const CreditStatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem 0 0 0;
  padding: 0 1.5rem;
  background: ${({ theme }) => theme.semantic.color.background.neutral200};
`;

export const CreditStatsRow = styled.div`
  display: flex;

  & label {
    display: flex;
    flex-direction: column;
    margin: 1rem 0;
    font-size: 1.5rem;
    width: 100%;
  }

  & label:nth-child(2) {
    margin-left: 2.5rem;
  }
`;

export const CreditStatsDivider = styled.div`
  border-bottom: 1px solid ${theme.semantic.color.text.neutral400};
`;

export const MarginInfoIcon = styled(InfoIcon)`
  margin-left: 0.8rem;
`;

export { SMAccountsNewLine } from '../../../../styles/commonAccountDetails.style';
export { SMAccountsTitle } from '../../../../styles/commonAccountDetails.style';
export { SmAccountsSectionStyle } from '../../../../styles/commonAccountDetails.style';
export { TooltipContractWrapper } from '../../../../styles/commonAccountDetails.style';
export { CardExpireDate } from '../../../../styles/commonAccountDetails.style';
export { CardNumber } from '../../../../styles/commonAccountDetails.style';
export { PaymentIcon } from '../../../../styles/commonAccountDetails.style';
export { AccName } from '../../../../styles/commonAccountDetails.style';
export { SpanDisplayFlexInline } from '../../../../styles/commonAccountDetails.style';
export { SeparatorForTitle } from '../../../../styles/commonAccountDetails.style';
export { Separator } from '../../../../styles/commonAccountDetails.style';
export { SectionContent } from '../../../../styles/commonAccountDetails.style';
export { SectionTitle } from '../../../../styles/commonAccountDetails.style';
export { cardWithShadow } from '../../../../styles/commonAccountDetails.style';
