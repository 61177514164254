export const statusTransitions = {
  aws: [
    { status: 'pending', options: ['account_pending', 'active', 'delete_pending', 'deleted'] },
    { status: 'account_pending', options: ['active', 'create_contract_pending', 'delete_pending'] },
    { status: 'create_contract_pending', options: ['contract_pending'] },
    { status: 'contract_pending', options: ['active'] },
    { status: 'contract_change_pending', options: ['active'] },
    { status: 'active', options: ['delete_pending'] },
    { status: 'delete_pending', options: ['deleted'] },
  ],
  gcp: [
    { status: 'pending', options: ['account_pending', 'delete_pending'] },
    { status: 'account_pending', options: ['active', 'delete_pending'] },
    { status: 'active', options: ['delete_pending'] },
    { status: 'delete_pending', options: ['deleted'] },
  ],
};
