import React from 'react';
import moment from 'moment';

import * as S from './AccountMarketplaceDetailsPage.styles';
import { SectionTitle } from '../../../styles/commonAccountDetails.style';
import { useDispatch, useSelector } from 'react-redux';
import { StoreInterface } from '../../../interfaces/storeInterfaces';
import { StatusCircle, StatusOption, StatusDropdown } from '../AccountsMarketplaces.styles';
import { PlusIcon } from '@redislabsdev/redis-ui-icons/monochrome';
import { Button } from '@redislabsdev/redislabs-ui-components';
import { ACCOUNTS_MARKETPLACES_WRITE } from '../../../constants/permissionsConstants';
import AssignMultiAccountMarketplaceModal from './AssignMultiAccountMarketplaceModal';
import AccountMarketplaceStatusUpdateModal from './AccountMarketplaceStatusUpdateModal';
import { statusTransitions } from '../constants';

const AccountMarketplaceDetailsPageSmAccountsSection: React.FC = () => {
  const dispatch = useDispatch();
  const marketplaceDetails = useSelector(
    (state: StoreInterface) => state.accountMarketplaceDetailsPage.accountMarketplaceDetails
  );
  const accountsMarketplacesStatuses = useSelector(
    (state: StoreInterface) => state.accountsMarketplacesPage.accountsMarketplacesStatuses
  );

  const [showModal, setShowModal] = React.useState(false);
  const permissions = useSelector((state: StoreInterface) => state.rootPage.permissions);
  const canWriteAccountsMarketplaces = permissions.includes(ACCOUNTS_MARKETPLACES_WRITE);

  const { smAccounts, is3P1C, marketplace } = marketplaceDetails;
  const canShowStatusDropdown = canWriteAccountsMarketplaces && !is3P1C;
  const canShowAddAccountButton = canWriteAccountsMarketplaces && !is3P1C;

  const getOptionalStatuses = (currentStatus) => {
    const transitionsByMarketplace = statusTransitions[marketplace] || [];
    const optionalStatusCodes = transitionsByMarketplace.find(({ status }) => status === currentStatus)?.options || [];
    return accountsMarketplacesStatuses.filter(({ code }) => optionalStatusCodes.includes(code));
  };

  const smAccountsColumnNames = [
    {
      header: 'SM Account ID',
      accessor: 'smAccountId',
      disableFilters: true,
      disableSortBy: true,
      width: 15,
      Cell: ({ value }) => {
        return <div>{value}</div>;
      },
    },
    {
      header: 'SM Account Name',
      accessor: 'smAccountName',
      disableFilters: true,
      disableSortBy: true,
      width: 15,
      Cell: ({ value }) => {
        return <div>{value || 'N/A'}</div>;
      },
    },
    {
      header: 'Mapping Status',
      accessor: 'status',
      disableFilters: true,
      disableSortBy: true,
      width: 15,
      Cell: ({ value, row }) => {
        const status = accountsMarketplacesStatuses.find(({ code }) => code === value);
          return !canShowStatusDropdown ? (
            <div>
              <StatusCircle status={value} />
              <span>{status?.label}</span>
            </div>
          ) : (
            <div>
              <StatusDropdown
                dataTestIdSuffix="dropdown-status"
                items={getOptionalStatuses(status?.code)}
                selectedItem={status}
                renderItem={(item) => (
                  <StatusOption>
                    <StatusCircle status={item.code} />
                    <span>{item.label}</span>
                  </StatusOption>
                )}
                onSelectedItemChange={(item) => {
                  dispatch({
                    type: 'setAccountMarketplaceStatusUpdateModalValues',
                    payload: {
                      showModal: true,
                      accountMarketplaceId: row.values.accountMarketplaceId,
                      status: item.selectedItem.code,
                    },
                  });
                }}
              />
            </div>
          );
      },
    },
    {
      header: 'Mapping ID (Dev use only)',
      accessor: 'accountMarketplaceId',
      disableFilters: true,
      disableSortBy: true,
      width: 15,
      Cell: ({ value }) => {
        return <div>{value || 'N/A'}</div>;
      },
    },
    {
      header: 'Created',
      accessor: 'createdAt',
      disableFilters: true,
      disableSortBy: true,
      width: 10,
      Cell: ({ value }) => {
        return <div>{value ? moment(value).format('DD-MMM-YYYY') : 'N/A'}</div>;
      },
    },
    {
      header: 'Last Updated',
      accessor: 'updatedAt',
      disableFilters: true,
      disableSortBy: true,
      width: 10,
      Cell: ({ value }) => {
        return <div>{value ? moment(value).format('DD-MMM-YYYY') : 'N/A'}</div>;
      },
    },
  ];

  return (
    <>
      <S.InfoSection>
        <SectionTitle>
          <span>SM Accounts</span>
          {canShowAddAccountButton && (
            <Button variant="secondary" onClick={() => setShowModal(true)}>
              <PlusIcon />
            </Button>
          )}
        </SectionTitle>
        {smAccounts && smAccounts.length > 0 ? (
          <S.AccountsTable columns={smAccountsColumnNames} data={smAccounts} noPagination />
        ) : (
          <S.SectionContent>
            <label>No SM Accounts found</label>
          </S.SectionContent>
        )}
      </S.InfoSection>
      <AssignMultiAccountMarketplaceModal isOpen={showModal} onOpenChange={setShowModal} />
      <AccountMarketplaceStatusUpdateModal />
    </>
  );
};

export default AccountMarketplaceDetailsPageSmAccountsSection;
