import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, SvgIcon } from '@redislabsdev/redislabs-ui-components';
import { StoreInterface } from '../../interfaces/storeInterfaces';
import { updateStateOnInputType } from '../../utils/common.utils';
import ClearAllAndApplyButtons from '../contractsPage/ClearAllAndApplyButtons';
import * as CS from '../../styles/common.style';
import { filterSectionLabels, PROVIDERS } from '../../constants/accountsMarketplacesPageConstants';
import * as S from './AccountsMarketplaces.styles';
import {
  getAccountsMarketplacesTableData,
  getAccountsMarketplacesConfiguration,
} from './AccountsMarketplaces.api';

const AccountsMarketplacesPageFilterSection: React.FC = () => {
  const dispatch = useDispatch();
  const filterItems = useSelector(
    (state: StoreInterface) => state.accountsMarketplacesPage.accountsMarketplacesFilterItems
  );
  const accountsMarketplacesStatuses = useSelector(
    (state: StoreInterface) => state.accountsMarketplacesPage.accountsMarketplacesStatuses
  );
  const { status, marketplace, smAccountId, marketplaceId } = filterItems;

  const updateStateOnInputTypeWrapper = (event) =>
    updateStateOnInputType({
      eTarget: event.target,
      action: 'setAccountsMarketplacesFilterItems',
      dispatch,
    });

  useEffect(() => {
    const callFun = async () => {
      if (accountsMarketplacesStatuses.length <= 1) {
        await getAccountsMarketplacesConfiguration(dispatch);
      }
    };

    callFun();
  }, []); // eslint-disable-line

  return (
    <CS.FilterSectionWrapper>
      <CS.FilterSection singleRow>
        <CS.FilterRow>
          <label htmlFor="status">
            {accountsMarketplacesStatuses.length <= 1 ? (
              <CS.StatusLoading>Loading...</CS.StatusLoading>
            ) : (
              <>
                {filterSectionLabels.status}
                <S.StyledDropdownMenu
                  dataTestIdSuffix="dropdown-status"
                  items={accountsMarketplacesStatuses}
                  selectedItem={accountsMarketplacesStatuses.find(({ code }) => code === status)}
                  renderItem={(item) => (
                    <S.StatusOption>
                      <S.StatusCircle status={item.code} />
                      <span>{item.label}</span>
                    </S.StatusOption>
                  )}
                  onSelectedItemChange={(item) => {
                    dispatch({
                      type: 'setAccountsMarketplacesFilterItems',
                      payload: { status: item.selectedItem.code },
                    });
                  }}
                />
              </>
            )}
          </label>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="marketplace">
            <>
              {filterSectionLabels.marketplace}
              <S.StyledDropdownMenu
                dataTestIdSuffix="dropdown-marketplace"
                items={PROVIDERS}
                selectedItem={PROVIDERS.find(({ code }) => code === marketplace)}
                renderItem={(item) => (
                  <div>
                    <SvgIcon width="20" height="20" name={item.icon} /> {item.label}
                  </div>
                )}
                onSelectedItemChange={(item) => {
                  dispatch({
                    type: 'setAccountsMarketplacesFilterItems',
                    payload: { marketplace: item.selectedItem?.code },
                  });
                }}
              />
            </>
          </label>
          <label htmlFor="smAccountId">
            {filterSectionLabels.smAccountId}
            <Input
              autoComplete="new-sm-account-id"
              data-testid="textinput--sm-account-id"
              width="18rem"
              placeholder="All"
              type="text"
              name="smAccountId"
              id="smAccountId"
              value={smAccountId}
              onChange={(event) => updateStateOnInputTypeWrapper(event)}
            />
          </label>
          <label htmlFor="marketplaceId">
            {filterSectionLabels.marketplaceId}
            <Input
              autoComplete="new-marketplace-id"
              data-testid="textinput--marketplace-id"
              width="18rem"
              placeholder="All"
              type="text"
              name="marketplaceId"
              id="marketplaceId"
              value={marketplaceId}
              onChange={(event) => updateStateOnInputTypeWrapper(event)}
            />
          </label>
        </CS.FilterRow>
        <ClearAllAndApplyButtons
          applyButtonCallback={() => getAccountsMarketplacesTableData(filterItems, dispatch)}
          clearAllCallback={() =>
            dispatch({
              type: 'resetAccountsMarketplacesPageFilterItems',
            })
          }
        />
      </CS.FilterSection>
    </CS.FilterSectionWrapper>
  );
};

export default AccountsMarketplacesPageFilterSection;
