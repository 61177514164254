import * as I from '../pages/contractsPage/contractsPage.types';

const contractsFilterInputsOnly = {
  contractId: 'Contract ID',
  customerName: 'Customer name',
  invoiceNumber: 'NS Invoice',
  smAccountId: 'SM Account ID',
  smAccountName: 'SM account name',
};

export const contractsFilterSectionLabels = {
  ...contractsFilterInputsOnly,
  startDate: 'Start Date',
  endDate: 'End Date',
  status: 'Status',
  clearAll: 'Clear All',
  applyFilters: 'Apply filters',
};

export const defaultPageFilters: I.PageFilterItems = {
  contractId: '',
  customerName: '',
  startDateFrom: null,
  startDateTo: null,
  endDateFrom: null,
  endDateTo: null,
  invoiceNumber: '',
  status: {
    code: '',
    label: 'All',
  },
  smAccountId: '',
  smAccountName: '',
};

const defaultTableFilters: I.TableFilterItems = {
  sortBy: '',
  sortDirection: 'desc',
  pageSize: 10,
  offset: 0,
  pageIndex: 0,
  pageCount: 0,
};

export const defaultFilterItems: I.FilterItems = {
  ...defaultPageFilters,
  ...defaultTableFilters,
};

export const defaultContractInfo: I.DefaultContractInfoInterface = {
  accounts: new Map(),
  accWithDetails: new Map(),
  comments: '',
  contactName: '',
  contractId: '',
  credit: '',
  discount: '',
  customerEmail: '',
  customerName: '',
  creditConsumed: '',
  overUsage: '',
  endDate: null,
  startDate: null,
  gracePeriod: '',
  invoiceNumber: '',
  onDemandChargeStart: '',
  redislabsEmailList: '',
  status: '',
  expandedContractId: '',
  renewalContractId: '',
  remainingCredit: '',
  creditConsumptions: [],
};

export const defaultGraceContractInfo: I.DefaultGraceContractInfoInterface = {
  contractId: '',
  endDate: null,
  selectedEndDate: null,
};
