import { createStore, Store } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import reducer from './reducers/reducer';
import { StoreInterface } from './interfaces/storeInterfaces';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['accountsMarketplacesPage', 'accountMarketplaceDetailsPage'],
};

const persistedReducer = persistReducer(persistConfig, reducer);

const store: Store<StoreInterface> = createStore(persistedReducer, undefined);

export const persistor = persistStore(store);

export default store;
