import {
  defaultFilterItems,
  defaultPageFilters,
  defaultModalValues,
} from '../constants/accountsMarketplacesPageConstants';
import {
  AccountMarketplaceStatus,
  MarketplaceProducts,
  MultiAccountMarketplaceTableData,
} from '../interfaces/accountMarketplaceStore.type';

export const accountsMarketplacesPageDefaultState = {
  accountsMarketplacesFilterItems: defaultFilterItems,
  accountsMarketplacesTableData: [] as MultiAccountMarketplaceTableData[],
  accountMarketplaceStatusUpdateModal: defaultModalValues,
  loadingAccountsMarketplacesTableData: false,
  accountsMarketplacesStatuses: [{ code: '', label: 'All' }] as AccountMarketplaceStatus[],
  marketplaceProducts: { aws: [], gcp: [] } as MarketplaceProducts,
  loadingAccountsMarketplacesConfig: true,
};

export default function accountsMarketplacesPageReducer(state, action) {
  const { accountsMarketplacesPage } = state;

  switch (action.type) {
    case 'setLoadingAccountsMarketplacesTableData':
      return {
        ...state,
        accountsMarketplacesPage: {
          ...accountsMarketplacesPage,
          loadingAccountsMarketplacesTableData: action.payload,
        },
      };

    case 'setAccountsMarketplacesTableData': {
      return {
        ...state,
        accountsMarketplacesPage: {
          ...accountsMarketplacesPage,
          accountsMarketplacesTableData: action.payload,
        },
      };
    }

    case 'setAccountsMarketplacesFilterItems': {
      const { accountsMarketplacesFilterItems } = accountsMarketplacesPage;
      return {
        ...state,
        accountsMarketplacesPage: {
          ...accountsMarketplacesPage,
          accountsMarketplacesFilterItems: {
            ...accountsMarketplacesFilterItems,
            ...action.payload,
          },
        },
      };
    }

    case 'resetAccountsMarketplacesPageFilterItems': {
      const { accountsMarketplacesFilterItems } = accountsMarketplacesPage;

      return {
        ...state,
        accountsMarketplacesPage: {
          ...accountsMarketplacesPage,
          accountsMarketplacesFilterItems: {
            ...accountsMarketplacesFilterItems,
            ...defaultPageFilters,
          },
        },
      };
    }

    case 'setAccountsMarketplacesStatuses': {
      return {
        ...state,
        accountsMarketplacesPage: {
          ...accountsMarketplacesPage,
          accountsMarketplacesStatuses: action.payload,
        },
      };
    }

    case 'setMarketplaceProducts': {
      return {
        ...state,
        accountsMarketplacesPage: {
          ...accountsMarketplacesPage,
          marketplaceProducts: action.payload,
        },
      };
    }

    case 'setLoadingAccountMarketplaceConfig': {
      return {
        ...state,
        accountsMarketplacesPage: {
          ...accountsMarketplacesPage,
          loadingAccountsMarketplacesConfig: action.payload,
        },
      };
    }

    case 'setAccountMarketplaceStatusUpdateModalValues': {
      const { accountMarketplaceStatusUpdateModal } = accountsMarketplacesPage;
      return {
        ...state,
        accountsMarketplacesPage: {
          ...accountsMarketplacesPage,
          accountMarketplaceStatusUpdateModal: {
            ...accountMarketplaceStatusUpdateModal,
            ...action.payload,
          },
        },
      };
    }

    case 'resetAccountMarketplaceStatusUpdateModalValues': {
      return {
        ...state,
        accountsMarketplacesPage: {
          ...accountsMarketplacesPage,
          accountMarketplaceStatusUpdateModal: {
            ...defaultModalValues,
          },
        },
      };
    }

    default:
      return state;
  }
}
