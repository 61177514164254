import styled from 'styled-components';
import { cardWithShadow } from '../../../styles/commonAccountDetails.style';
import { Table } from '@redislabsdev/redislabs-ui-components';

export const LoadingWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding: 10rem 5rem;
  font-size: 2rem;
`;

export const InfoSection = styled.div`
  margin-bottom: 2rem;
  border-radius: 5px;
  ${cardWithShadow}
`;

export const SectionContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 1.5rem;
  gap: 4rem;

  label {
    display: flex;
    font-size: 1.5rem;
    color: #4d6992;
    flex-direction: column;
  }
`;

export const AccountsTable = styled(Table)`
  box-shadow: none;
  background-color: transparent;
  font-size: 1.5rem;
  color: #01112a;

  div[role='columnheader'] {
    color: #4d6992;
  }

  div[role='rowgroup'] {
    max-height: none;
  }
`;

export const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const ModalErrors = styled.div`
  color: red;
  font-size: 1.5rem;
`;

export const ModalActions = styled.div`
  display: flex;
  margin-top: 1rem;
  justify-content: flex-end;
`;
