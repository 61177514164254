import styled from 'styled-components';
import { DropdownMenu } from '@redislabsdev/redislabs-ui-components';

export const StyledMarketplaceContractsDropdownMenu = styled(DropdownMenu)`
  width: 17rem;
`;

export const TableWrapper = styled.div`
  & div[role='row'] {
    font-size: 1.55rem;
  }

  & div[data-role='table-body'] {
    cursor: pointer;
  }
`;
