import { MultiAccountMarketplace } from '../interfaces/accountMarketplaceStore.type';

export const accountMarketplaceDetailsPageDefaultState = {
  loadingAccountMarketplaceDetails: false,
  accountMarketplaceDetailsFilterItems: {
    marketplaceId: '',
    marketplace: '',
  },
  accountMarketplaceDetails: {} as MultiAccountMarketplace,
};

export const accountMarketplaceDetailsPageReducer = (state, action) => {
  const { accountMarketplaceDetailsPage } = state;

  switch (action.type) {
    case 'setLoadingAccountMarketplaceDetails':
      return {
        ...state,
        accountMarketplaceDetailsPage: {
          ...accountMarketplaceDetailsPage,
          loadingAccountMarketplaceDetails: action.payload,
        },
      };

    case 'setAccountMarketplaceDetailsFilterItems':
      const { accountMarketplaceDetailsFilterItems } = accountMarketplaceDetailsPage;

      return {
        ...state,
        accountMarketplaceDetailsPage: {
          ...accountMarketplaceDetailsPage,
          accountMarketplaceDetailsFilterItems: {
            ...accountMarketplaceDetailsFilterItems,
            ...action.payload,
          },
        },
      };

    case 'setAccountMarketplaceDetails':
      return {
        ...state,
        accountMarketplaceDetailsPage: {
          ...accountMarketplaceDetailsPage,
          accountMarketplaceDetails: action.payload,
        },
      };

    case 'resetAccountMarketplaceDetailsPage':
      return {
        ...state,
        accountMarketplaceDetailsPage: {
          ...accountMarketplaceDetailsPageDefaultState,
        },
      };

    default:
      return state;
  }
};
