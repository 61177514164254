import { api, buildUrl } from '../../api/config';
import { displayErrors } from '../rootPage/RootPage.utils';
import { prepareFilterTableRequestData } from '../../utils/filterTable.utils';

export async function getAccountsMarketplacesConfiguration(dispatch): Promise<void> {
  try {
    const statusesRequest = api.get(`${buildUrl('bousers')}/configuration/account-marketplace`);
    const productsRequest = api.get(`${buildUrl('accountsMarketplaces')}/products`);
    const response = await Promise.all([statusesRequest, productsRequest]);
    if (response.every((res) => res.status === 200)) {
      dispatch({ type: 'setAccountsMarketplacesStatuses', payload: response[0].data.statuses });
      dispatch({ type: 'setMarketplaceProducts', payload: response[1].data });
      dispatch({ type: 'setLoadingAccountMarketplaceConfig', payload: false });
    }
  } catch (err) {
    displayErrors('Failed to get account marketplace configuration', err);
  }
}

export async function getAccountsMarketplacesTableData(reqParams, dispatch): Promise<void> {
  dispatch({ type: 'setLoadingAccountsMarketplacesTableData', payload: true });
  dispatch({
    type: 'setAccountsMarketplacesFilterItems',
    payload: {
      pageIndex: reqParams.pageIndex,
      pageSize: reqParams.pageSize,
      offset: reqParams.offset,
      sortBy: reqParams.sortBy,
      sortDirection: reqParams.sortDirection,
    },
  });

  const response = await api.get(`${buildUrl('accountsMarketplaces')}/multiAccount`, {
    params: prepareFilterTableRequestData(reqParams, [
      'sortBy',
      'sortDirection',
      'pageCount',
      'pageIndex',
    ]),
  });

  if (response.status === 200) {
    dispatch({
      type: 'setAccountsMarketplacesFilterItems',
      payload: {
        pageCount: Math.ceil(response.data.itemsTotal / reqParams.pageSize),
      },
    });

    dispatch({
      type: 'setAccountsMarketplacesTableData',
      payload: response.data.items,
    });
  }
  dispatch({ type: 'setLoadingAccountsMarketplacesTableData', payload: false });
}
