import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import * as S from './AccountMarketplaceDetailsPage.styles';
import { Checkbox, Input, Modal, TextButton } from '@redislabsdev/redis-ui-components';
import { SubmitButton } from '@redislabsdev/redislabs-ui-components/ui/components/Table/components/TableWithActionRows/TableRowButtons/TableRowButtons.style';
import { addSmAccountMapping } from './AccountMarketplaceDetails.api';

interface IProps {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
}

const AssignMultiAccountMarketplaceModal: FC<IProps> = ({ isOpen, onOpenChange }) => {
  const dispatch = useDispatch();
  const { id: marketplaceId } = useParams<{ id: string }>();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const marketplace = searchParams.get('marketplace') as string;
  const [accountId, setAccountId] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async () => {
    if (!accountId.trim()) {
      setError('Account ID cannot be empty.');
      return;
    }
    // Clear states before submitting
    onOpenChange(false);
    setIsChecked(false);
    setError('');
    // Call API to assign account
    await addSmAccountMapping(marketplaceId, marketplace, parseInt(accountId), dispatch);
  };

  return (
    <Modal.Compose open={isOpen} onOpenChange={onOpenChange}>
      <Modal.Content.Compose>
        <Modal.Content.Close style={{ marginBottom: '1rem' }} />
        <Modal.Content.Header title="Add SM Account Mapping" />
        <Modal.Content.Body.Compose>
          <S.ModalContentWrapper>
            <label htmlFor="smAccountId">
              SM Account ID
              <Input
                onChange={(e) => setAccountId(e.target.value)}
                placeholder="Enter SM Account ID"
              />
            </label>
            <Checkbox
              onCheckedChange={(checked) => setIsChecked(checked as boolean)}
              label="I verify and approve the addition of this account"
            ></Checkbox>
            {error && <S.ModalErrors>{error}</S.ModalErrors>}
            <S.ModalActions>
              <SubmitButton onClick={handleSubmit} disabled={!isChecked}>
                Submit
              </SubmitButton>
            </S.ModalActions>
          </S.ModalContentWrapper>
        </Modal.Content.Body.Compose>
      </Modal.Content.Compose>
    </Modal.Compose>
  );
};

export default AssignMultiAccountMarketplaceModal;
