import { Moment } from 'moment';

export interface PageFilterItems {
  contractId: string;
  customerName: string;
  startDateFrom: Moment | null;
  startDateTo: Moment | null;
  endDateFrom: Moment | null;
  endDateTo: Moment | null;
  invoiceNumber: string;
  status: { code: string; label: string; marketPlaceRelated?: boolean };
  smAccountId: string;
  smAccountName: string;
}

export interface TableFilterItems {
  sortDirection: string;
  sortBy: string;
  offset: number;
  pageCount: number;
  pageSize: number;
  pageIndex: number;
}

export interface FilterItems extends PageFilterItems, TableFilterItems {}

export interface DefaultContractInfoInterface {
  accounts: Map<any, any>; // eslint-disable-line
  accWithDetails: Map<any, any>; // eslint-disable-line
  comments: string;
  contactName: string;
  contractId: string;
  credit: string;
  discount: string;
  customerEmail: string;
  customerName: string;
  creditConsumed: string | null;
  overUsage: string | null;
  endDate: Moment | null;
  startDate: Moment | null;
  gracePeriod: string;
  invoiceNumber: string;
  onDemandChargeStart: string;
  redislabsEmailList: string;
  status: string;
  expandedContractId: string;
  renewalContractId?: string;
  remainingCredit: string;
  creditConsumptions?: CreditConsumption[];
}

export interface DefaultGraceContractInfoInterface {
  contractId: string;
  endDate: Moment | null;
  selectedEndDate: Moment | null;
}

export enum CreditConsumer {
    SHARDS_USAGE = 'shards_usage',
    NETWORK_USAGE = 'network_usage'
}

export interface CreditConsumption {
  consumer: CreditConsumer;
  consumption: string;
  recentFrom: Moment | null;
}
