import styled from 'styled-components';
import { DropdownMenu, Table } from '@redislabsdev/redislabs-ui-components';
import { theme } from '@redislabsdev/redis-ui-styles';

export const AccountsMarketplacesTable = styled(Table)`
  & div[data-role='table-header'] {
    & div[role='row'] {
      padding: 0;
    }
  }

  & div[role='cell'] {
    flex-wrap: wrap;
  }
  & div[role='row'] {
    font-size: 1.55rem;
    height: auto;
    padding: 1.5rem 0;

    &:last-child {
      padding-bottom: 0;
    }
  }
`;

export const TableHeader = styled.div`
  display: flex;
  align-items: center;
`;

export const TableHeaderText = styled.span`
  margin-right: 0.5rem;
`;

export const TableCellGroupItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const StyledDropdownMenu = styled(DropdownMenu)`
  width: 18rem;

  button {
    color: ${theme.semantic.color.text.neutral700}};
  }
}`;

export const StatusDropdown = styled(DropdownMenu)`
  width: 20rem;

  button {
    color: ${theme.semantic.color.text.neutral700};
    border: none;
  }
`;

export const StatusOption = styled.div`
  display: flex;
  align-items: center;
`;

export const StatusCircle = styled.span<{ status: string }>`
  height: 1rem;
  width: 1rem;
  background-color: ${({ status }) => {
    if (status === 'active') return '#4FDAE0';
    if (status === 'deleted') return '#F74B57';
    return '#FFAF2B';
  }};
  border-radius: 50%;
  flex-shrink: 0;
  display: inline-block;
  margin-right: 0.5rem;
`;

