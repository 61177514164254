import { useParams } from 'react-router-dom';
import * as CS from '../../../styles/common.style';

const AccountMarketplaceDetailsPageTitle: React.FC = () => {
  const { id: marketplaceId } = useParams<{ id: string }>();

  return (
    <CS.PageTitleAndActions>
      <CS.JustifyToLeft>
        <CS.PageHeaderTitle>
          Account Marketplace Mapping Details: {marketplaceId}
        </CS.PageHeaderTitle>
      </CS.JustifyToLeft>
    </CS.PageTitleAndActions>
  );
};

export default AccountMarketplaceDetailsPageTitle;
