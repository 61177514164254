import { IconNamesEnum } from '@redislabsdev/redislabs-ui-components';
import * as I from '../pages/accountsMarketplacesPage/AccountsMarketplacesPage.types';

export const TABLE_DEFAULT_SORT = [{ id: 'marketplace', desc: true }];

export const PROVIDERS = [
  { code: 'aws', label: 'AWS', icon: IconNamesEnum.AMAZON },
  { code: 'gcp', label: 'Google Cloud', icon: IconNamesEnum.GOOGLE_CLOUD },
];

export const filterSectionLabels = {
  status: 'Status',
  marketplace: 'Provider',
  smAccountId: 'SM Account ID',
  marketplaceId: 'Customer Ref ID',
};

export const defaultPageFilters: I.PageFilterItems = {
  marketplace: '',
  smAccountId: '',
  marketplaceId: '',
  status: '',
};

const defaultTableFilters: I.TableFilterItems = {
  sortBy: '',
  sortDirection: 'desc',
  pageSize: 10,
  offset: 0,
  pageIndex: 0,
  pageCount: 0,
};

export const defaultModalValues: I.StatusUpdateModalValues = {
  showModal: false,
  accountMarketplaceId: null,
  status: '',
};

export const defaultFilterItems: I.FilterItems = {
  ...defaultPageFilters,
  ...defaultTableFilters,
};
